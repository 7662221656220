/* import __COLOCATED_TEMPLATE__ from './reviewable-user.hbs'; */
import Component from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";

export default Component.extend({
  @discourseComputed("reviewable.user_fields")
  userFields(fields) {
    return this.site.collectUserFields(fields);
  },
});
