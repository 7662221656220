import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import DButton from "discourse/components/d-button";
import TopicParticipants from "discourse/components/topic-map/topic-participants";
import replaceEmoji from "discourse/helpers/replace-emoji";
import i18n from "discourse-common/helpers/i18n";
import and from "truth-helpers/helpers/and";
import lt from "truth-helpers/helpers/lt";
import not from "truth-helpers/helpers/not";
const TRUNCATED_LINKS_LIMIT = 5;
export default class TopicMapExpanded extends Component {
    @tracked
    allLinksShown = false;
    get topicLinks() {
        return this.args.topicDetails.links;
    }
    get participants() {
        return this.args.topicDetails.participants;
    }
    @action
    showAllLinks() {
        this.allLinksShown = true;
    }
    get linksToShow() {
        return this.allLinksShown ? this.topicLinks : this.topicLinks.slice(0, TRUNCATED_LINKS_LIMIT);
    }
    static{
        template(`
    {{#if this.participants}}
      <section class="avatars">
        <TopicParticipants
          @title={{i18n "topic_map.participants_title"}}
          @userFilters={{@userFilters}}
          @participants={{this.participants}}
        />
      </section>
    {{/if}}
    {{#if this.topicLinks}}
      <section class="links">
        <h3>{{i18n "topic_map.links_title"}}</h3>
        <table class="topic-links">
          <tbody>
            {{#each this.linksToShow as |link|}}
              <tr>
                <td>
                  <span
                    class="badge badge-notification clicks"
                    title={{i18n "topic_map.clicks" count=link.clicks}}
                  >
                    {{link.clicks}}
                  </span>
                </td>
                <td>
                  <TopicMapLink
                    @attachment={{link.attachment}}
                    @title={{link.title}}
                    @rootDomain={{link.root_domain}}
                    @url={{link.url}}
                    @userId={{link.user_id}}
                  />
                </td>
              </tr>
            {{/each}}
          </tbody>
        </table>
        {{#if
          (and
            (not this.allLinksShown)
            (lt TRUNCATED_LINKS_LIMIT this.topicLinks.length)
          )
        }}
          <div class="link-summary">
            <span>
              <DButton
                @action={{this.showAllLinks}}
                @title="topic_map.links_shown"
                @icon="chevron-down"
                class="btn-flat"
              />
            </span>
          </div>
        {{/if}}
      </section>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
let TopicMapLink = class TopicMapLink extends Component {
    get linkClasses() {
        return this.args.attachment ? "topic-link track-link attachment" : "topic-link track-link";
    }
    get truncatedContent() {
        const truncateLength1 = 85;
        const content1 = this.args.title || this.args.url;
        return content1.length > truncateLength1 ? `${content1.slice(0, truncateLength1).trim()}...` : content1;
    }
    static{
        template(`
    <a
      class={{this.linkClasses}}
      href={{@url}}
      title={{@url}}
      data-user-id={{@userId}}
      data-ignore-post-id="true"
      target="_blank"
      rel="nofollow ugc noopener noreferrer"
    >
      {{#if @title}}
        {{replaceEmoji this.truncatedContent}}
      {{else}}
        {{this.truncatedContent}}
      {{/if}}
    </a>
    {{#if (and @title @rootDomain)}}
      <span class="domain">
        {{@rootDomain}}
      </span>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
